import { getLoadPickDate } from 'app/util/loads/pick-drop-date-calculator';
import { AppointmentType } from 'shared/enums/appointment-type.enum';
import { SpecializedEquipmentType } from 'shared/enums/specialized-equipment-type.enum';
import { StopType } from 'shared/enums/stop-type.enum';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { BaseModel } from 'shared/models/base.model';
import { AvailableLoadDetail } from 'shared/models/loads/available-load-detail.model';
import { LoadSummaryAddress } from 'shared/models/loads/load-summaries/load-summary-address.model';
import { LoadSummaryCarrierOffer } from 'shared/models/loads/load-summaries/load-summary-carrier-offer';
import { AvailableLoadStop } from 'shared/models/loads/stops/available-load-stop.model';

export type SearchCategory = 'BestRateAllIn' | 'BestRatePerMile' | 'ShortestDeadhead';

export type DisplayTags = 'ReloadsAvailable';

export class AvailableLoadSummary extends BaseModel {
  number: number;
  measurementType: UnitOfMeasure;
  originCity: string;
  originStateProvinceCode: string;
  originCountryCode: string;
  originLatitude?: number;
  originLongitude?: number;
  destinationCity: string;
  destinationStateProvinceCode: string;
  destinationCountryCode: string;
  destinationLatitude?: number;
  destinationLongitude?: number;
  destinationPostalCode: string;
  destinationDeadheadDistance: number;
  destinationHaversineDistance: number;
  hasWork?: boolean;
  weight?: number;
  stopCount?: number;
  stops?: AvailableLoadStop[];
  distance?: number;
  equipmentCode: string;
  specializedEquipmentCode: SpecializedEquipmentType;
  haversineDistance: number;
  deadheadDistance?: number;
  equipmentLength?: number;
  equipmentWidth?: number;
  equipmentHeight?: number;
  binRateCost: BinRateCost;
  hasDropTrailer: boolean;
  isTeamFlag: boolean;
  isTankerEndorsementRequired: boolean;
  isRegulatedByStf: boolean;
  isHazMat: boolean;
  rateObject: boolean;
  isNotOfferable?: boolean;
  carrierOffers?: LoadSummaryCarrierOffer[];
  bookingContactPhoneNumber: string;
  // categories and tags do not exist on the api dto. These will be used
  // for adobe analytics
  categories?: SearchCategory[];
  tags?: DisplayTags[];
  carrierTier: string;
  isWebExclusive?: boolean;

  private _originDeadhead: number;
  private _destinationDeadhead: number;
  private _endorsementsList: string;
  private _pickStartTimeV2: Date;
  private _pickEndTimeV2: Date;

  private dropoffDateRange: {
    startDateTime: string,
    endDateTime: string
  };

  private pickupDateRange: {
    startDateTime: string,
    endDateTime: string
  };

  private readyDateTime?: string;
  private deliverByDateTime?: string;
  private activityDate?: string;



  get endorsementsList() {
    if (!this._endorsementsList) {
      const endorsements = [];
      if (this.isTankerEndorsementRequired) {
        endorsements.push('Tanker');
      }
      if (this.isHazMat) {
        endorsements.push('HazMat');
      }
      if (this.isRegulatedByStf) {
        endorsements.push('STF');
      }

      const response = endorsements.join(', ');
      this._endorsementsList = response !== '' ? response : null;
    }
    return this._endorsementsList;
  }

  get dropStartTime(): Date {
    return this.dropoffDateRange ? this.processDate(this.dropoffDateRange.startDateTime) : this.deliverByDate;
  }
  get dropStartTimeV2(): Date {
    return this.dropStartTime;
  }

  get dropEndTime(): Date {
    return this.dropoffDateRange ? this.processDate(this.dropoffDateRange.endDateTime) : this.deliverByDate;
  }
  get dropEndTimeV2(): Date {
    return this.dropEndTime;
  }

  get pickStartTime(): Date {
    return this.pickupDateRange ? this.processDate(this.pickupDateRange.startDateTime) : this.readyDate;
  }

  get pickEndTime(): Date {
    return this.pickupDateRange ? this.processDate(this.pickupDateRange.endDateTime) : this.readyDate;
  }

  get readyDate(): Date {
    return this.processDate(this.readyDateTime);
  }

  set readyDate(date: Date) {
    this.readyDateTime = this.convertDateToDateString(date);
    this.clearCachedPickStartEndTimes();
  }

  get deliverByDate(): Date {
    return this.processDate(this.deliverByDateTime);
  }

  get activityDateTime(): Date {
    return this.processDate(this.activityDate);
  }

  get firstStop(): AvailableLoadStop {
    const stop = this.stops
      ?.sort((a, b) => a.stopNumber - b.stopNumber)
      .find(stop => stop.stopType === StopType.PICKUP);
    return stop;
  }

  get appointmentStartDate(): Date {
    const stop = this.firstStop;
    if (stop?.appointmentType === AppointmentType.APPOINTMENT_SET) {
      return this.processDate(stop?.arriveByStartDateTime);
    }
  }

  get appointmentEndDate(): Date {
    const stop = this.firstStop;
    if (stop?.appointmentType === AppointmentType.APPOINTMENT_SET) {
      return this.processDate(stop?.arriveByEndDateTime);
    }
  }

  get appointmentType(): string {
    const stop = this.firstStop;
    return stop?.appointmentType?.toString();
  }

  get pickArriveByStartTime(): Date {
    const stop = this.firstStop;
    return this.processDate(stop?.arriveByStartDateTime);
  }
  get pickArriveByEndTime(): Date {
    const stop = this.firstStop;
    return this.processDate(stop?.arriveByEndDateTime);
  }

  get pickWarehouseOpeningDateTime(): Date {
    return this.processTime(this.firstStop?.warehouse?.openingDateTime);
  }
  get pickWarehouseClosingDateTime(): Date {
    return this.processTime(this.firstStop?.warehouse?.closingDateTime);
  }

  clearCachedPickStartEndTimes() {
    this._pickStartTimeV2 = null;
    this._pickEndTimeV2 = null;
  }

  get pickStartTimeV2(): Date {
    if (this._pickStartTimeV2) {
      return this._pickStartTimeV2;
    }
    // Lazy load (This may need to be expanded on if we ever have a reason for updating the underlying values needed for this calculation)
    const stop = this.firstStop;
    const apptType = stop?.appointmentType;
    const arriveByDate = this.processDate(stop?.arriveByStartDateTime);
    const warehouseOpeningTime = this.processTime(stop?.warehouse.openingDateTime);
    this._pickStartTimeV2 = getLoadPickDate(arriveByDate, this.activityDateTime, apptType, warehouseOpeningTime);
    return this._pickStartTimeV2;
  }

  get pickEndTimeV2(): Date {
    if (this._pickEndTimeV2) {
      return this._pickEndTimeV2;
    }

    // Lazy load (This may need to be expanded on if we ever have a reason for updating the underlying values needed for this calculation)
    const stop = this.firstStop;
    const apptType = stop?.appointmentType;
    const arriveByDate = this.processDate(stop?.arriveByEndDateTime);
    const warehouseClosingTime = this.processTime(stop?.warehouse.closingDateTime);
    this._pickEndTimeV2 = getLoadPickDate(arriveByDate, this.activityDateTime, apptType, warehouseClosingTime);
    return this._pickEndTimeV2;
  }

  set deliverByDate(date: Date) {
    this.deliverByDateTime = this.convertDateToDateString(date);
    this.clearCachedPickStartEndTimes();
  }

  constructor(json?: AvailableLoadSummaryJSON) {
    super(json);
    this._originDeadhead = null;
    this._destinationDeadhead = null;
    this.clearCachedPickStartEndTimes();

    this.stops = json?.stops?.map(s => new AvailableLoadStop(s));
  }

  toJson(): AvailableLoadSummaryJSON {
    return Object.assign({}, this) as any;
  }

  get binRateCostScore(): number {
    return this.binRateCost?.score || 0;
  }

  get totalCost(): number {
    return this.binRateCost?.totalCost || 0;
  }

  get isOfferable(): boolean {
    return this.binRateCost?.binOfferable
  }

  get pickCount(): number {
    return this.stops?.filter(s => s.stopType === StopType.PICKUP).length || 0;
  }

  get dropCount(): number {
    return this.stops?.filter(s => s.stopType === StopType.DROP_OFF).length || 0;
  }

  get currencyCode(): string {
    return this.binRateCost?.currencyCode || '';
  }

  get hasRateObject(): boolean {
    return Boolean(this.binRateCost);
  }

  static fromAvailableLoadDetail(loadDetail: AvailableLoadDetail) {
    const summary = new AvailableLoadSummary();
    summary.number = loadDetail.number;
    summary.weight = loadDetail.weight;
    summary.equipmentCode = loadDetail.equipment.code;
    summary.distance = loadDetail.distance;
    summary.binRateCost = loadDetail.binRateCost;
    summary.stopCount = loadDetail.stopDetails.length;
    summary.carrierTier = loadDetail.carrierTier;
    summary.specializedEquipmentCode = loadDetail.specializedEquipmentCode;
    summary.hasDropTrailer = loadDetail.isDropTrailer;
    summary.isTeamFlag = loadDetail.isTeamLoad;
    summary.isTankerEndorsementRequired = loadDetail.isTankerEndorsementRequired;
    summary.isRegulatedByStf = loadDetail.isRegulatedByStf;
    summary.isHazMat = loadDetail.isHazMat;

    const originAddress = loadDetail.getFirstPick().warehouse.address;
    summary.originCity = originAddress.city;
    summary.originStateProvinceCode = originAddress.stateCode;
    summary.originCountryCode = originAddress.countryCode;
    summary.originLatitude = originAddress.geolocation?.latitude;
    summary.originLongitude = originAddress.geolocation?.longitude;

    const destinationAddress = loadDetail.getLastDrop().warehouse.address;
    summary.destinationCity = destinationAddress.city;
    summary.destinationStateProvinceCode = destinationAddress.stateCode;
    summary.destinationCountryCode = destinationAddress.countryCode;
    summary.destinationLatitude = destinationAddress.geolocation?.latitude;
    summary.destinationLongitude = destinationAddress.geolocation?.longitude;
    summary.destinationPostalCode = destinationAddress.zipCode;

    return summary;
  }

  get originDeadhead() {
    if (!this._originDeadhead) {
      this._originDeadhead = this.deadheadDistance || this.haversineDistance;
    }
    return this._originDeadhead;
  }

  get destinationDeadhead() {
    if (!this._destinationDeadhead) {
      this._destinationDeadhead = this.destinationDeadheadDistance || this.destinationHaversineDistance;
    }
    return this._destinationDeadhead;
  }

  hasEndorsements(): boolean {
    return Boolean(this.isTankerEndorsementRequired || this.isRegulatedByStf || this.isHazMat);
  }

  getOriginAddress(): LoadSummaryAddress {
    return (this.originStateProvinceCode === this.originCountryCode ?
      new LoadSummaryAddress({
        streetName: null,
        street2: null,
        cityName: this.originCity,
        stateCode: null,
        zipcode: null,
        country: this.originCountryCode,
        location: null, // e.g. "City, ST CN"
        fullLocation: null, // e.g. "Sadf↵Cheesetown, AL 55555↵US↵"
        companyName: null,
        county: null
      }) :
      new LoadSummaryAddress({
        streetName: null,
        street2: null,
        cityName: this.originCity,
        stateCode: this.originStateProvinceCode,
        zipcode: null,
        country: this.originCountryCode,
        location: null, // e.g. "City, ST CN"
        fullLocation: null, // e.g. "Sadf↵Cheesetown, AL 55555↵US↵"
        companyName: null,
        county: null
      }));
  }

  getDestinationAddress(): LoadSummaryAddress {
    return (this.destinationStateProvinceCode === this.destinationCountryCode ?
      new LoadSummaryAddress({
        streetName: null,
        street2: null,
        cityName: this.destinationCity,
        stateCode: null,
        zipcode: null,
        country: this.destinationCountryCode,
        location: null, // e.g. "City, ST CN"
        fullLocation: null, // e.g. "Sadf↵Cheesetown, AL 55555↵US↵"
        companyName: null,
        county: null
      }) :
      new LoadSummaryAddress({
        streetName: null,
        street2: null,
        cityName: this.destinationCity,
        stateCode: this.destinationStateProvinceCode,
        zipcode: null,
        country: this.destinationCountryCode,
        location: null, // e.g. "City, ST CN"
        fullLocation: null, // e.g. "Sadf↵Cheesetown, AL 55555↵US↵"
        companyName: null,
        county: null
      }));
  }
}
