import { BaseModel } from 'shared/models/base.model';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';

export class ReloadsResponse extends BaseModel {
  loads: AvailableLoadSummary[];
  totalLoadCount: number;
  reloadSearchRadius: number;
  compatibleWithReloads: boolean;

  constructor(loads: AvailableLoadSummary[], totalLoadCount, reloadSearchRadius: number, compatibleWithReloads: boolean) {
    super();
    this.loads = loads;
    this.totalLoadCount = totalLoadCount;
    this.compatibleWithReloads = compatibleWithReloads;
    this.reloadSearchRadius = reloadSearchRadius;
  }
}
