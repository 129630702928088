import { BaseModel } from 'shared/models/base.model';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';

export class AvailableLoadSummaryResponse extends BaseModel {
  results: AvailableLoadSummary[];
  performedSuggestedLoadSearch: boolean;

  constructor(results: AvailableLoadSummary[], performedSuggestedLoadSearch?: boolean) {
    super();
    this.results = results;
    this.performedSuggestedLoadSearch = performedSuggestedLoadSearch ?? false;
  }
}
